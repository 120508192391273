//
// Icon buttons
//

// General styles

.btn {
  position: relative;
  text-transform: $btn-text-transform;
  transition: $transition-base;
  letter-spacing: $btn-letter-spacing;
  font-size: $input-btn-font-size;
  will-change: transform;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
  }

  &:not(:last-child) {
    margin-right: .5rem;
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: .5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: .5rem;
  }
}


// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}


// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
}


// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
  i:not(.fas):not(.fab) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-neutral {
  color: theme-color("primary");
}

.btn-hopsti-green {
  color: white;
  background-color: #3DBDB4;
  border-color: #3DBDB4;

  &:hover {
    color: white;
    background-color: #277C74;
    border-color: #277C74;
    box-shadow: none;
    transform: none;
  }
}

.btn-hopsti-green-outline {
  color: #3DBDB4;
  background-color: white;
  border-color: #3DBDB4;

  &:hover {
    color: white;
    background-color: #277C74;
    border-color: #277C74;
    box-shadow: none;
    transform: none;
  }

  &.active {
    z-index: 2;
    color: white !important;
    background-color: #3DBDB4 !important;
    border-color: #3DBDB4 !important;
  }
}

.btn-category-hopsti-pink {
  color: #ED6B9F;
  border-color: #ED6B9F !important;

  &:hover {
    box-shadow: none;
    transform: none;
    color: white;
    background-color: #ED6B9F;
    border-color: #ED6B9F !important;
  }

  &.active {
    z-index: 2;
    color: white;
    // color: white !important;
    background-color: #ED6B9F !important;
    border-color: #ED6B9F !important;
  }
}

.hopsti-star {
  color: #ED6B9F !important;
  border-color: #ED6B9F !important;

  &:hover {
    background-color: white;
    color: #ED6B9F !important;
    border-color: #C14678;
    box-shadow: none;
    transform: none;
  }

  &.active {
    z-index: 2;
    background-color: white;
    color: #ED6B9F !important;
    border-color: #C14678;
  }
}

.hopsti-star-outline {
  background-color: white;
  border-color: #ED6B9F !important;

  &:hover {
    background-color: white;
    color: #ED6B9F !important;
    border-color: #C14678;
    box-shadow: none;
    transform: none;
  }

  &.active {
    z-index: 2;
    background-color: white;
    color: #ED6B9F !important;
    border-color: #C14678;
  }
}

.btn-hopsti-pink-outline {
  background-color: white;
  border-color: #ED6B9F !important;

  &:hover {
    // background-color: #C14678;
    border-color: #C14678;
    box-shadow: none;
    transform: none;
  }

  &.active {
    z-index: 2;
    // color: white !important;
    //background-color: #ED6B9F !important;
    border-color: #ED6B9F !important;
  }
}

.btn-hopsti-pink {
  color: white;
  background-color: #ED6B9F;
  border-color: #ED6B9F;

  &:hover {
    color: white;
    background-color: #C14678;
    border-color: #C14678;
    box-shadow: none;
    transform: none;
  }
}
