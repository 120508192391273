body {
    /*font-family: 'proxima-nova', sans-serif;*/
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    margin: 0;
}

.btn-facebook-hopsti {
    color: #fff;
    height: 43px !important;
    background-color: #3b5999;
    border-color: #3b5999;
    display: inline-flex;
    align-items: center;
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;*/
    /*padding: 5px 12px 5px 0;*/
    padding-right: 10px;
    border-radius: 2px;
    font-size: 13px;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    /*margin-bottom: 10px;*/
}

.btn-google-plus {
    height: 42px !important;
    margin-top: 0 !important;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 300 !important;
    font-size: 13px !important;
}

.btn-google-plus > div {
    margin-right: 3px !important;
}

i.fa-facebook-f {
    margin-right: 5px;
    padding: 10px;
    border-radius: 2px;
    width: 18px;
}

.bg-hopsti {
    background-color: #24a8ef !important;
}

.autocomplete-wrapper {
    width: 100% !important;
}

.location-search-input {
    width: 100% !important;
    font-size: 0.875rem;
    transition: all 0.15s ease-in-out;
}

.autocomplete-loading-container, .suggestion-item, .suggestion-item--active {
    font-size: 0.875rem;
    padding: 5px;
}

.navToggle {
    width: 160px !important;
}

.toggle-label {
    line-height: 13px;
}

.navToggle {
    height: 1.8rem;
}

.custom-toggle-slider:before {
    border-radius: 0.25rem !important;
}

.custom-toggle-slider:after {
    border-radius: 0.25rem !important;
}

.custom-toggle-slider {
    /*border: 1px solid #3DBDB4 !important;*/
    border-radius: 0.25rem !important;
}

.isChecked {
    background-color: #3DBDB4 !important;
    border: 1px solid #3DBDB4 !important;
}

.isNotChecked {
    background-color: #ED6B9F !important;
    border: 1px solid #ED6B9F !important;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
    background: #fff;
    transform: translateX(131px) !important;
}

.isNotChecked input + .custom-toggle-slider {
    border: 1px solid #ED6B9F !important;
}

.isChecked input:checked + .custom-toggle-slider {
    border: 1px solid #3DBDB4 !important;
}

.custom-toggle-slider:before {
    bottom: 2px;
    height: 21px;
    width: 21px;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
    color: #fff;
    bottom: 1px;
}

.toggle-user-role {
    display: flex !important;
}

.tabHeight {
    min-height: 154px
}

.match-container {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

/* Landing page custom css */
.bg-hopsti-green {
    background-color: #3DBDB4;
    border-color: #3DBDB4;
}

.hopsti-green {
    color: #3DBDB4 !important;
}

.bg-hopsti-pink {
    background-color: #ED6B9F !important;
    border-color: #ED6B9F !important;
}

.hopsti-pink {
    color: #ED6B9F;
}

.navbar {
    padding: 0;
}

.navbar-horizontal .navbar-brand img {
    height: 70px;
}

.hopsti-jumbotron {
    background-image: url("../img/theme/foor-taust.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

@media screen and (max-width: 650px) {
    .hopsti-jumbotron {
        /*background-image: url("../img/theme/2 (1).png");*/
        background-color: #E4F4F5;
        background-image: none;
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
    }

    .jumbotron-sub-heading {
        display: none;
    }

    .display-2 {
        text-align: center !important;
    }

    .nav-footer {
        text-align: center !important;
    }

    .jumbotron-btn-container {
        text-align: center;
    }
}

.footer-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-jumbotron {
    font-size: 19px;
    margin: 0 !important;
    margin-top: 10px !important;
}

.hopsti-polygon {
    fill: #3DBDB4;
}

.newsletter {
    width: 200px;
    height: 60px;
}

.hopsti-icon {
    width: 50px;
    margin: 5px;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    color: #444;
    text-decoration: none;
}

.icon-dashboard-container:hover {
    cursor: pointer;
}

.icon-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 14px;
    color: #444;
    text-decoration: none;
}

.hopsti-dashboard-icon {
    width: 30px;
    margin: 5px;
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-decoration: none;
    min-height: 580px;
}
.video-container-kov {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-decoration: none;
    min-height: 280px;
}

.video-container > .card {
    height: 100%;
}
.video-container-kov > .card {
    height: 100%;
}

.hopsti-logo {
    width: 230px;
    height: auto !important;
}

.el-fund-logo {
    /*width: 150px;*/
    height: 75px;
    width: auto;
}

/* End of landing page custom css */

.hopsti-role-toggle {
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

.custom-toggle-slider {
    border: 1px solid #3DBDB4;
    padding: 4px;
}

/* user role toggle circle */
.custom-toggle-slider:before {
    background-color: #ffffff;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
}

/* user role toggle text */
.custom-toggle-slider:after {
    color: #ffffff;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
}

.disabled-link {
    pointer-events: none;
}

.newsletter-btn {
    height: 100%;
}

.youtube-video {
    max-width: 350px;
}

.fa-facebook-square {
    font-size: 20px;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
    padding-left: 5px;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
}

.custom-toggle-slider:after {
    padding-right: 5px;
    color: #ffffff;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
}


/* Autocomplete search */
.search-container > div > input {
    font-size: 1rem;
}

mark {
    padding: 0 !important;
}

.rbt-highlight-text {
    background-color: white;
    color: #3DBDB4;
}

/* End of autocomplete search */

/* Service card */
.hopsti-card {
    /*max-width: 1200px;*/
    box-shadow: 2px 6px 6px rgba(75, 77, 80, 0.15);
    margin-bottom: 25px;
}

.hopsti-card-body {
    padding: 0;
}

.col-favourite {
    min-width: 10px;
    max-width: 20px;
}

.col-avatar {
    min-width: 50px;
    max-width: 80px;
}

.col-last-activity {
    min-width: 50px;
    max-width: 120px;
}

.col-service-name {
    min-width: 180px;
    max-width: 250px;
}

.col-name {
    min-width: 100px;
    max-width: 200px;
}

.col-service-price {
    min-width: 130px;
    max-width: 150px;
}

.col-service-distance {
    min-width: 100px;
    max-width: 150px;
}

@media screen and (max-width: 810px) {
    .hopsti-card {
        margin-bottom: 40px;
    }

    .col-avatar {
        margin-top: -12px;
    }

    .service-card-row {
        text-align: center;
        justify-content: center;
    }

    .col-service-name {
        max-width: 500px;
    }

    .col-service-price {
        max-width: 500px;
    }

    .col-service-distance {
        max-width: 500px;
    }

    .col-card-actions {
        text-align: center !important;
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 810px) {
    .col-service-info > span {
        height: 18px !important;
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

@media screen and (min-width: 811px) {
    .col-avatar {
        /*width: 80px;*/
        display: flex;
        align-items: center;
        height: 50px;
        width: 50px;
    }

    .avatar > img {
        width: 55px;
        height: 55px;
    }
}

@media screen and (max-width: 1395px) {
    .col-last-activity {
        display: none;
    }
}

@media screen and (max-width: 1195px) {
    .col-name {
        display: none;
    }
}

/*@media screen and (max-width: 1030px) {*/
/*    .col-service-info {*/
/*        display: none;*/
/*    }*/
/*}*/

@media screen and (max-width: 810px) {
    .col-avatar, .col-favourite, .fa-phone-square, .fa-envelope {
        display: none;
    }

    .actions-row {
        justify-content: center;
    }

    .hopsti-card-body {
        padding: 10px;
    }

    .col-service-name, .col-service-price, .col-service-distance {
        padding: 5px;
    }
}

/*@media screen and (max-width: 768px) {*/
/*    .col-service-info {*/
/*        display: block;*/
/*    }*/
/*}*/

@media screen and (min-width: 769px) {
    .col-card-actions {
        margin-left: auto !important;
    }

    .actions-row {
        margin-left: auto !important;
    }
}

.st0 {
    fill: none;
    stroke: #3DBDB4;
    stroke-width: 15;
    stroke-miterlimit: 10;
    width: 30px;
}

.hopsti-dashboard-icon:hover .st0 {
    fill: #3DBDB4;
    stroke: #3DBDB4;
    stroke-width: 15;
    stroke-miterlimit: 10;
    width: 30px;
}

.hopsti-dashboard-icon.active .st0 {
    fill: #3DBDB4;
    stroke: #3DBDB4;
    stroke-width: 15;
    stroke-miterlimit: 10;
    width: 30px;
}

.hopstiTime > .rdtPicker .rdtTimeToggle:before {
    content: "Vali kellaaeg"
}

.btn-hopsti-nav {
    width: 250px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0.25rem;
    border: 0;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.awssld__bullets--active {
    background-color: #3DBDB4 !important;
}

.offer-card-footer, .offer-item {
    background-color: #f8f9fe;
}

.offer-item {
    border: none;
}

td {
    padding: 5px !important;
}

th {
    padding-left: 5px !important;
    padding-right: 5px !important;
    background-color: #3DBDB4 !important;
    color: white !important;
}

th.dow {
    border-radius: 0 !important;
}

th.rdtPrev {
    border-radius: 0 !important;
}

th.rdtSwitch {
    border-radius: 0 !important;
}

th.rdtNext {
    border-radius: 0 !important;
}

.rdtDay.rdtActive, .rdtDay.rdtActive:hover, .rdtDay.start-date, .rdtDay.end-date, .rdtDay.middle-date, .rdtDay.rdtToday.rdtActive, .rdtDay.start-date:hover, .rdtDay.end-date:hover, .rdtDay.middle-date:hover {
    background-color: #3DBDB4 !important;
    color: #fff !important;
}

.rdtPicker {
    padding: 0;
}

.matches-modal {
    max-width: 1200px !important;
}

.matches-modal-sm {
    max-width: 700px !important;
}

.clickable {
    cursor: pointer;
}

.service-detail-img {
    height: 90px !important;
    width: 90px !important;
}

.select2-selection__rendered {
    word-wrap: break-word !important;
    text-overflow: inherit !important;
    white-space: normal !important;
}

.select2-selection--single {
    height: 100% !important;
}

.search-container > div > input {
    border-radius: 30px;
    padding-left: 25px;
}

.search-container > div > input:focus {
    border-color: #3DBDB4;
}

.pagination > .active > button {
    background-color: #3DBDB4 !important;
    border-color: #3DBDB4 !important;
}

.fa-heart.active {
    color: #ED6B9F;
}

.fa-star {
    font-size: 16px;
}

/*.search-container > div > input:hover {*/
/*    border-radius: 30px;*/
/*    border-color: #277C74;*/
/*}*/

.blurred-image {
    filter: blur(3px) !important;
}

.contactAlert .alert {
    width: 450px
}

.galleryFix .image-gallery-slide {
    left: 0;
}

.progress-bar {
    background-color: #ED6B9F !important;
}

.progress-label span {
    color: #ED6B9F !important;
}

.image-list-group-item {
    background-color: #f8f9fe !important;
}

.image-column {
    height: auto;
    width: 150px;
}

.image-item {
    height: auto;
    width: 100%;
}

.page-item.active .page-link {
    background-color: #3DBDB4;
    border-color: #3DBDB4;
}

tr > td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table td {
    vertical-align: middle;
}

tbody {
    background-color: white;
}

._loading_overlay_spinner {
    margin: 0;
}

._loading_overlay_overlay {
    background: rgba(0, 0, 0, 0.05) !important;
}

.css-50etie svg circle {
    stroke: #3DBDB4;
}

.recharts-wrapper {
    margin: 0 !important;
}

span.select2-selection__rendered {
    font: 400 14px system-ui;
}

.form-control:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #3DBDB4;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #3DBDB4;
    background-color: #3DBDB4;
}

.image-gallery-content.fullscreen {
    background: white !important;
}

.image-gallery-fullscreen-button.active::before {
    color: #3DBDB4 !important;
}

.image-gallery-fullscreen-button::before {
    color: #3DBDB4 !important;
}

.image-gallery-thumbnail.active {
    border: 4px solid #3DBDB4 !important;
}

.nav-footer .nav-link {
    font-size: 1rem;
}

.nav-link > span {
    font-size: 1rem;
}

.fa-facebook-square {
    font-size: 48px;
}

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {

    background-color: #3DBDB4;

}

.noUi-horizontal .noUi-handle.noUi-active, .noUi-vertical .noUi-handle.noUi-active {
    box-shadow: 0 0 0 2px #3DBDB4;
}


.noUi-connect {
    background: #3DBDB4;
}

.noUi-target {
    margin-top: 0;
}

.input-group-append {
    height: 31px;
}

.currencyInput {
    margin-bottom: 0;
}

.newsLetterSignUpMesg {
    color: #fff;
}

/* Mobile dashboard view */
@media screen and (max-width: 810px) {
    .btn-hopsti-nav {
        width: auto;
        font-size: 12px;
    }
}

.fa-medal.active {
    color: #ffaf00;
}

.fa-exclamation-circle.active {
    color: #ED6B9F;
}

.assess.active {
    color: #3DBDB4;
    font-weight: 900;
    font-size: 18px;
}

.assess {
    font-weight: 900;
    font-size: 18px;
}

.assess-btn {
    white-space: normal;
    width: 350px;
    /*min-height: 100px;*/
}

.kov-sp-th {
    vertical-align: top !important;
}

.filter-label {
    margin-bottom: 0;
}

.toggle-container {
    width: 218px;
}

.badge {
    font-size: 90%;
}

.contact-form-row > div > .form-group {
    margin-bottom: 0.7rem;
}

.fa-phone-square, .fa-envelope {
    display: block !important;
}

.quantity-container {
    min-width: 65px !important;
}

/*.badge-floating {*/
/*    transform: translate(164%, 55%) !important;*/
/*}*/

/*.image-gallery {*/
/*    width: 100% !important;*/
/*    height: auto !important;*/
/*}*/

/*.image-gallery-slide img {*/
/*    width: 100% !important;*/
/*    height: auto !important;*/
/*    max-height: 80vh !important;*/
/*    object-fit: cover !important;*/
/*    overflow: hidden !important;*/
/*    object-position: center center !important;*/
/*}*/

/*.fullscreen .image-gallery-slide img {*/
/*    max-height: 100vh !important;*/
/*}*/

.hopsti-select2-invalid > span > span > span {
    border: 1px solid red !important;
}

.hopsti-select2-valid > span > span > span {
    border: 1px solid #2dce89 !important;
}

.hopsti-dashboard-icon {
    width: 45px;
}